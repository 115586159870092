.imageCell {
  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
}

.imageCell:hover {
  object-fit: cover;
  vertical-align: bottom;
  opacity: 40%;
}
.container:hover {
  position: relative;
  text-align: center;
}

.container {
  height: 40vh;
  flex-grow: 1;
}
.grid-container {
  display: flex;
  flex-wrap: wrap;
  background-color: rgb(22, 119, 255);
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bold;
  font-size: large;
}
